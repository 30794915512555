
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonAvatar, IonToggle } from "@ionic/vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { dbUser, dbCustomers } from "../services/localbase";

import moment from "moment";

export default {
    name: "Tab3",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonAvatar,
        IonToggle,
    },
    setup() {
        const user = JSON.parse(localStorage.getItem("userInfo"));

        const router = useRouter();

        const localMode = JSON.parse(localStorage.getItem("localMode"));

        const mode = ref(false);
        if (localMode == "1") {
            mode.value = true;
        } else {
            mode.value = false;
        }

        function handleClienti(event) {
            mode.value = !mode.value;
            localStorage.setItem("localMode", mode.value ? "1" : "0");
        }

        /**
         * Set user avatar image
         */
        function setImage(user) {
            if (user.users_avatar) {
                return `https://domovip.ingegnosuite.it/uploads/${user.users_avatar}`;
            } else {
                return `https://domovip.ingegnosuite.it/images/user.png`;
            }
        }

        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }

        /**
         * Return '-' if a customer field is null
         */
        function checkField(field) {
            if (!field || field === "") {
                return " - ";
            } else {
                return field;
            }
        }

        /**
         * Force app update
         */
        function updateApp() {
            window.location.reload(true);
        }

        /**
         * Delete localbase CLIENTI and USER database
         */
        async function deleteDb() {
            /*       const deleteUser = await dbUser.collection("user").delete();
      const deleteDocuments = await dbDocuments
        .collection("documents")
        .delete();
      const deleteRichieste = await dbRichieste
        .collection("richieste")
        .delete();
      const deletePresenze = await dbPresenze.collection("presenze").delete();
      const deleteTimbrature = await dbTimbrature
        .collection("timbrature")
        .delete(); */

            dbUser.delete().then((response) => {
                console.log(response);
            });
            dbCustomers.delete().then((response) => {
                console.log(response);
            });
        }

        /**
         * Delete Localbase databases and clear localStorage
         * Redirect user to Login page
         */
        function logOut() {
            deleteDb().then(() => {
                localStorage.clear();
                router.replace("/login");
            });
        }

        return {
            user,
            setImage,
            //userImage,
            dateFormat,
            checkField,
            logOut,
            updateApp,
            handleClienti,
            mode,
        };
    },
};
